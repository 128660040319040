<template>
    <div style="margin-top: 100px">
        <div
            class="container-bg"
            style="margin-top: 5% !important; width: 80%; margin: auto"
        >
            <div class="container-fg">
                <v-divider style="margin-bottom: 1%; border-color: gray" />
                <div style="display: flex; flex-direction: row">
                    <div style="display: block; margin: auto">
                        <p
                            class="text-h7 nav-font pl-3"
                            style="
                                margin: 0;
                                margin-left: -15px;
                                margin-top: 25%;
                            "
                            >RMA HAS BEEN SUCCESSFULLY</p
                        >
                        <h1
                            style="
                                font-size: 36px;
                                letter-spacing: -0.08rem;
                                margin-bottom: 15%;
                            "
                            >CANCELED</h1
                        >
                        <v-btn
                            color="primary"
                            outlined
                            style="margin-bottom: 25%"
                            @click="returnHome"
                            >Return To Home</v-btn
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'RmaCanceled',
    data() {
        return {}
    },
    methods: {
        returnHome() {
            this.$router.push(`/`)
        }
    }
}
</script>
